/* Welcome header */

.welcome-header {
    background: rgb(52, 103, 124);
    background: linear-gradient(90deg, rgba(52, 103, 124, 1) 0%, rgba(62, 71, 88, 1) 100%);

    h1 {
        width: 90%;
        margin: 0 auto;
        text-align: center;
        color: white;
        font-size: 3rem;
        padding-top: 8%;
        line-height: 3.5rem;
    }
    p {
        width: 90%;
        margin: 0 auto;
        text-align: center;
        color: white;
        opacity: 0.6;
        font-size: 1.7rem;
        line-height: 3rem;
        margin: 1% auto 4% auto;
    }
}

.welcome-header__partner {
    margin: 1% auto !important;
}

.welcome-header__appsearch-container {
    width: 70vw;
    max-width: 700px;
    margin: 0 auto;
    display: block;
    height: 30px;
}

.graph {
    width: 100vw;
}
.cma-logo {
    display: block;
    margin: 0 auto;
    padding-bottom: 3%;
    width: 100%;
    max-width: 100px;
}

/* WEBSITE FEATURES */

.welcome-features {
    width: 80%;
    margin: 0 auto;
}
.welcome-features-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 3%;
    div {
        h2 {
            font-size: 2.5rem;
            margin: 4% 0;
            color: #2d677b;
            line-height: 3rem;
        }
        p {
            width: 50%;
            line-height: 1.6rem;
        }
        ul {
            list-style: none;
            line-height: 2.2rem;
        }
    }
}

.welcome-features-img {
    width: 41vw;
    max-width: 500px !important;
}

.welcome-grey-btn {
    background-color: #444;
    padding: 5px 10px;
    color: white;
    font-weight: bold;
    border-radius: 10px;
    margin-right: 10px;
}
.welcome-blue-btn {
    background-color: #41d0ff;
    padding: 5px 14px;
    color: white;
    font-weight: bold;
    border-radius: 10px;
    margin-right: 10px;
}
.welcome-green-btn {
    background-color: #3ab278;
    padding: 5px 10px;
    color: white;
    font-weight: bold;
    border-radius: 10px;
    margin-right: 10px;
}
.reverse {
    flex-direction: row-reverse;
    div {
        margin-left: 20%;
        p {
            width: 90%;
        }
    }
}

// Buttons

.welcome-cta-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 5% auto;
    height: 50px;
    a {
        button {
            width: 270px;
            height: 60px;
            padding: 3% 10%;
            border: none;
            border-radius: 10px;
            font-size: 1.5rem;
            font-weight: bold;
        }
    }
}
.whiteContainer,
.whiteContainerBtn {
    background-color: $inverse-header;
    color: #2c677b;
    border: none;
    transition: all 0.35s ease-in-out;
}

.greenContainer {
    background-color: #2c677b;
    color: white;
}

// Data plan

.welcome-data-plan {
    transition: all 0.35s ease-in-out;
    background: rgb(52, 103, 124);
    background: linear-gradient(90deg, rgba(52, 103, 124, 1) 0%, rgba(62, 71, 88, 1) 100%);
    display: flex;
    height: 500px;
    div {
        padding: 5% 0 5% 12%;
        width: 80%;
        margin-left: auto;
        h2 {
            color: white;
            font-size: 2.5rem;
            margin: 4% 0;
            line-height: 3rem;
        }
        p {
            width: 80%;
            line-height: 1.6rem;
            color: white;
        }
        ul {
            list-style: none;
            line-height: 2.2rem;
            li {
                color: white;
            }
        }
        button {
            margin: 3% 0;
            width: 300px;
            height: 60px;
            border: none;
            border-radius: 10px;
            font-size: 1.5rem;
            font-weight: bold;
        }
    }
}

.welcome-data__picture {
    width: 100%;
    max-width: 900px;
    margin-right: -3%;
    margin-top: -5%;
    margin-bottom: -5%;
    padding-right: 0;
}

// PERKS

.welcome-perks {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    background-color: white;
    width: 80%;
    margin: 3% auto;
}

.welcome-perks-container {
    margin: 3%;
    padding: 4% 2%;
    width: 45%;
    background-color: $inverse-header;
    border-radius: 10px;
    h2 {
        font-size: 2.5rem;
        margin: 4% 0;
        color: #2d677b;
        line-height: 3rem;
        text-align: center;
    }
    img {
        display: flex;
        margin: 4% auto;
        width: 100%;
        max-width: 400px;
    }
    ul {
        list-style: none;
        line-height: 2.5rem;
    }
}

// signup section

.signup-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2 {
        font-size: 4rem;
        color: #2d677b;
        text-align: center;
        font-weight: 700;
        line-height: 5rem;
        margin: 4% auto;
    }
    p {
        font-size: 2rem;
        text-align: center;
        font-weight: 500;
        line-height: 2rem;
    }
    button {
        color: white;
        background-color: $button-red;
        width: 300px;
        height: 70px;
        font-size: 1.8rem;
        text-align: center;
        font-weight: 500;
        border: none;
        border-radius: 10px;
        margin: 3% auto 30% auto;
        &:hover {
            background-color: #fff;
            color: $button-red;
        }
    }
}

.whiteContainer,
.whiteContainerBtn,
.greenContainer {
    display: none;
    transition: opacity 200ms;
    opacity: 0;
}

.welcome-data-plan:hover .whiteContainer {
    display: block;
    opacity: 1;
    transition: opacity 200ms;
    animation: fade-in 1s;
}

.welcome-features:hover .whiteContainerBtn {
    display: block;
    opacity: 1;
    transition: opacity 200ms;
    animation: fade-in 1s;
}
.welcome-features:hover .greenContainer {
    display: block;
    opacity: 1;
    transition: opacity 200ms;
    animation: fade-in 1s;
}

// responsive

// TABLETS

@media only screen and (max-width: 900px) {
    .whiteContainer,
    .whiteContainerBtn,
    .greenContainer {
        display: block;
        opacity: 1;
    }
    .welcome-features-container {
        flex-direction: column;
        div {
            text-align: center;
            p {
                width: 100%;
            }
            ul {
                text-align: left;
                margin-left: 3%;
            }
        }
    }
    .reverse div {
        margin-left: 0;
    }
    .welcome-perks {
        flex-direction: column;
        width: 90%;
    }
    .welcome-perks-container {
        width: 90%;
    }
    .signup-section {
        width: 80%;
        margin: 0 auto;
    }
}

@media only screen and (max-width: 750px) {
    .welcome-cta-container {
        flex-direction: column;
        a {
            margin-bottom: 3%;
        }
    }
    .greenContainer {
        margin-bottom: 24%;
    }
}

// MOBILE
@media only screen and (max-width: 400px) {
    .welcome-header {
        h1 {
            font-size: 2rem;
        }
        p {
            font-size: 1.2rem;
            line-height: 2rem;
        }
    }
    .welcome-features-container {
        div {
            h2 {
                font-size: 1.7rem;
            }
            p,
            ul,
            li {
                font-size: 0.8rem;
            }
        }
    }
    .welcome-features-img {
        width: 100vw;
        min-height: 260px;
        margin-bottom: 3%;
    }
    .welcome-cta-container a button,
    .welcome-data-plan div button,
    .signup-section button {
        font-size: 2rem;
        width: 200px;
        height: 40px;
        border: none;
        border-radius: 10px;
        font-size: 1rem;
        font-weight: bold;
        cursor: pointer;
    }
    .welcome-data__picture {
        display: none;
    }
    .welcome-data-plan div {
        padding: 0;
        margin: 2% auto;
        h2 {
            font-size: 1.7rem;
        }
        p {
            width: 90%;
        }
    }
    .welcome-perks-container {
        h2 {
            font-size: 1.5rem;
        }
        img {
            max-width: 200px;
        }
        p {
            margin: 10% 0;
            padding-left: 3%;
        }
    }
    .signup-section {
        h2 {
            font-size: 2rem;
            line-height: 2rem;
        }
        p {
            font-size: 1.3rem;
        }
    }
}
.help-button-container {
    display: flex;
    justify-content: flex-end;
    padding-right: 2%;
    position: fixed;
    bottom: 10px;
    right: -5px;
    z-index: 100;
}
.help-button {
    margin-bottom: 1%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    color: white;
    background-color: $button-red;
    border: none;
    padding-top: 7.5px;
    border-radius: 50%;
    width: 35px;
    height: 33px;
    &:hover {
        color: white;
        background-color: $button-red;
    }
    &:focus {
        color: white;
        background-color: $button-red;
    }
}

.feedbackModal {
    width: 50vw;
}
