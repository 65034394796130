.editable-cell {
	position: relative;
}

.editable-cell-value-wrap {
	padding: 5px 12px;
	cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
	padding: 4px 11px;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
}

td {
	font-size: 13px;
}

.appicon {
	display: inline-block;
	width: 40px;
	margin: 0 10px 0 0px;
	position: relative;
	vertical-align: top;
	z-index: 1;

	img {
		width: 40px;
		border-radius: 10px;
		margin-left: 2px;
	}
}

.app-status {
	display: flex;
}

.title-name {
	margin-top: 0;

	color: rgba(0, 0, 0, 0.85);
	font-weight: 500;
}

.title-info {
	width: 80%;
	text-align: start;
	display: inline-block;
	vertical-align: bottom;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;

	div {
		color: #111;
		margin-top: 4px;
		vertical-align: top;
		display: inline-block;
		position: relative;
		left: 55px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 70%;
		font-size: 0.9em;
		line-height: 1.3em;
	}

	p {
		display: block;
		color: rgba(52, 103, 124, 0.7);
		font-size: 0.7em;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}
