.beta {
  background: #f00;
  color: #fff;
  padding: 1px 4px;
  font-size: 0.7em;
  border-radius: 4px;
  width: 30px;
  text-align: center;
  position: absolute;
  left: 702px;
  top: 33px;
}

/* nav-tabs */

#content {
  height: 65px;
  /*
  z-index: 2000;
    position: fixed;
    top: 0;
    */
  width: 100%;
}

.logo-parent {
  height: 100%;
  text-align: center;
  margin: auto;
}

.main-logo {
  background: url(/assets/images/elements/APPlyzerLogoHead.svg) no-repeat;
  height: 35px;
  width: 160px;
  display: inline-flex;
  background-size: 100%;
  cursor: pointer;
  margin-bottom: 8px;
}

.functionrow {
  height: 100%;
  display: block;
  align-self: flex-end;
  overflow: auto;
  white-space: nowrap;
}

.nav-tabs {
  width: 100%;
  margin: auto;
  align-items: flex-end;
  height: 100%;
  background: linear-gradient(90deg, #34677c 0%, #3e4758 100%) !important;

  &__dropdown-overlay {
    z-index: auto;
    background-color: #fff;
    padding-top: 10px;
    width: 300px;
    align-content: baseline;
    display: grid;
    max-height: 90vh;

    box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;

    &--apps {
    }
  }

  button {
    padding: 12px 13px 16px 13px !important;
    font-size: 1em;
    color: #fff !important;
    min-width: 50px;
    background: transparent !important;
    transition: background 0.2s ease;
    border-radius: 4px 4px 0 0 !important;
    border: none !important;
    cursor: pointer;
    vertical-align: bottom;

    &:hover {
      background-color: #fff !important;
      color: #34677c !important;
    }
  }

  button.disabled {
    border: 1px solid #ccc;
    color: #aaa;
    border: none;
    color: #fff;
    opacity: 0.4;
  }

  button.dropdown-button,
  .dropdown-button > span {
    color: #fff;
    background-color: inherit;

    &:hover {
      background-color: inherit;
      opacity: 0.8;
      color: #fff !important;
    }

    &:focus {
      background-color: inherit;
      color: #fff;
    }
  }

  &__add-workspace {
    margin: 10px;
    background-color: rgb(1, 165, 1);
    color: white;
    border-color: rgb(1, 165, 1);
    border-radius: 5px;
  }

  .favourite-app {
    background-color: rgba(255, 255, 255, 0.15) !important;
    background-size: 47% !important;
    height: 50px;
    width: 60px;
    top: 0;
    padding: 0 !important;

    img {
      width: 25px;
      height: 25px;
      border-radius: 8px;
      margin-right: 7px;
      cursor: pointer;
      transition: background 0.3s ease;
    }

    img:hover {
      opacity: 0.7;
    }
  }

  &__profile {
    position: absolute;
    right: 0;
  }
}

.nav-tabs.icon {
  color: #ffffff !important;
  background-color: #ffffff26 !important;
  max-width: 50px;
  max-height: 45px;
  min-height: 45px;
  text-align: center;
  padding: 0px !important;
}

.nav-tabs.icon hover {
  color: #34677c !important;
  background-color: #ffffff !important;
}

.nav-tabs.icon.selected {
  color: #34677c !important;
  background-color: #ffffff !important;
}

.nav-tabs.icon span {
  display: block;
}

.tab-title {
  font-size: 5pt;
}

.nav-tabs.devices {
  margin: 40px 0 18px 0;
}

.nav-tabs .left {
  float: left;
  width: 100%;
  vertical-align: top;
  text-align: center;
}

.nav-tabs .right {
  float: right;
  width: 30%;
  vertical-align: top;
  margin-top: 0;
  text-align: right;
}

#functionnavbar.floatleft {
  width: 80%;
}

#functionnavbar .nav-tabs .left {
  text-align: left;
}

#functionnavbar .functions {
  position: relative;
  top: 25px;
}

/* Firefox Fix */
@-moz-document url-prefix() {
  #functionnavbar .functions {
    position: relative;
  }
}

.nav-tabs .selected {
  border: 1px solid rgba(255, 255, 255, 1);
  color: #34677c !important;
}

.nav-tabs button:hover:disabled {
  background-color: #f0f3f7 !important;
  color: #fff !important;
}

.nav-tabs button.disabled:hover {
  color: #fff !important;
}

.nav-tabs button:hover:before {
  position: absolute;
  float: left;
  top: -20px;
  left: 0;
  font-size: 0.8em;
  background: transparent;
  color: #ebeff1;
  cursor: pointer;
  z-index: 10000000;
  width: auto;
  height: 15px;
  vertical-align: middle;
  text-align: left;
  width: 140px;
  opacity: 0.6;
}

.nav-tabs button#workspaceManager {
  background-color: rgba(255, 255, 255, 0.15) !important;
  margin: 10px 0px 0 0px;
  height: 55px;
  width: auto;
  border-radius: 4px 0 0 0 !important;
  max-width: 300px;

  span {
    background: none;
    margin-right: 0;
    height: auto;
    max-width: 200px;
    font-size: 13px;
    white-space: nowrap;
    overflow: clip;
    text-overflow: ellipsis;

    &:hover {
      color: $table-header !important;
    }
  }

  &:hover {
    background-color: #ebeff1 !important;
    color: rgba(255, 255, 255, 0.15);
    background-size: 47% !important;
    border: 1px solid #fff !important;

    span {
      color: $table-header !important;
    }
  }
}

.nav-tabs button#workspaceManager.selected {
  background-color: #ebeff1 !important;
  color: #fff !important;
  background-size: 47% !important;
}

.nav-tabs button#workspaceManager.disabled {
  background: #fff url(/assets/images/icons/iconMyAppsWhite.svg) center center no-repeat;
  color: #fff !important;
  background-size: 47% !important;
}

.nav-tabs button#functionOverview {
  background-color: rgba(255, 255, 255, 0.15) !important;
  margin-right: 16px;
  background-size: 47% !important;
  border-radius: 0 4px 0 0 !important;
  height: 55px;
  width: 65px;
  border-left: 1px solid #e1ffff33 !important;

  &:hover {
    background-color: #ebeff1 !important;
    background-size: 47% !important;
    border: 1px solid #fff;
  }
}

.nav-tabs button#functionOverview.selected {
  background-color: #ebeff1 !important;
  color: #fff;
  background-size: 47% !important;
}

.nav-tabs button#functionOverview.disabled {
  background: #fff url(/assets/images/icons/iconMyAppsWhite.svg) center center no-repeat;
  color: #fff;
  background-size: 47% !important;
}

.nav-tabs button {
  background: rgba(255, 255, 255, 0.15);
}

.nav-tabs button.selected {
  background: rgba(245, 245, 245, 0.86);
  color: #3e4758;
}

.nav-tabs2 button span {
  font-size: 11pt;
  font-weight: bold;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  letter-spacing: 1px;
}

.nav-tabs button.groupSingle {
  border-radius: 4px 4px 0 0 !important;
  border-right: 0 !important;
  margin-right: 15px;
}

.nav-tabs button.groupSingleLeft {
  border-radius: 4px 4px 0 0 !important;
  border-right: 0 !important;
  margin-left: 15px;
}

.nav-tabs button.groupFirst {
  border-radius: 4px 0 0 0 !important;
  border-right: 0 !important;
}

.nav-tabs button.groupMid {
  border-left: 1px solid rgba(225, 255, 255, 0.2) !important;
  border-radius: 0 0 0 0 !important;
  border-right: 0 !important;
}

.nav-tabs button.groupLast {
  border-left: 1px solid rgba(225, 255, 255, 0.2) !important;
  border-radius: 0 4px 0 0 !important;
  margin-right: 15px;
}

.nav-tabs button#functionInfo {
  background: rgba(255, 255, 255, 0.15) url(/assets/images/icons/iconAppInfoWhite.svg) center center no-repeat !important;
  background-size: 47% !important;
}

.nav-tabs button#functionInfo:hover {
  background: #ebeff1 url(/assets/images/icons/iconAppInfo.svg) center center no-repeat !important;
  background-size: 47% !important;
  border: 1px solid #fff;
}

.nav-tabs button#functionInfo.selected {
  background: #ebeff1 url(/assets/images/icons/iconAppInfo.svg) center center no-repeat !important;
  color: #fff;
  background-size: 47% !important;
}

.nav-tabs button#functionInfo.disabled {
  background: #fff url(/assets/images/icons/iconAppInfoWhite.svg) center center no-repeat;
  border: none !important;
  background-size: 47% !important;
}

.nav-tabs button#functionFeatured {
  background: rgba(255, 255, 255, 0.15) url(/assets/images/icons/iconFeaturedWhite.svg) center center no-repeat !important;
  background-size: 50% !important;
}

.nav-tabs button#functionFeatured:hover {
  background: #ebeff1 url(/assets/images/icons/iconFeatured.svg) center center no-repeat !important;
  background-size: 50% !important;
  border: 1px solid #fff;
}

.nav-tabs button#functionFeatured.selected {
  background: #ebeff1 url(/assets/images/icons/iconFeatured.svg) center center no-repeat !important;
  color: #fff;
  background-size: 50% !important;
}

.nav-tabs button#functionFeatured.disabled {
  background: #fff url(/assets/images/icons/iconFeaturedWhite.svg) center center no-repeat;
  border-left: 1px solid #ccc !important;
  background-size: 50% !important;
}

.nav-tabs button#functionRanking {
  background: rgba(255, 255, 255, 0.15) url(/assets/images/icons/iconRankingsWhite.svg) center center no-repeat !important;
  background-size: 50% !important;
}

.nav-tabs button#functionRanking:hover {
  background: #ebeff1 url(/assets/images/icons/iconRankings.svg) center center no-repeat !important;
  background-size: 50% !important;
}

.nav-tabs button#functionRanking.selected {
  background: #ebeff1 url(/assets/images/icons/iconRankings.svg) center center no-repeat !important;
  color: #34677c !important;
  background-size: 50% !important;
}

.nav-tabs button#functionHistory {
  background: rgba(255, 255, 255, 0.15) url(/assets/images/icons/iconHistoryWhite.svg) center center no-repeat !important;
  background-size: 61% !important;
}

.nav-tabs button#functionHistory:hover {
  background: #ebeff1 url(/assets/images/icons/iconHistory.svg) center center no-repeat !important;
  background-size: 61% !important;
}

.nav-tabs button#functionHistory.selected {
  background: #ebeff1 url(/assets/images/icons/iconHistory.svg) center center no-repeat !important;
  color: #34677c !important;
  background-size: 61% !important;
}

.nav-tabs button#functionHistory.disabled {
  background: #fff url(/assets/images/icons/iconHistoryWhite.svg) center center no-repeat;
  border-left: 1px solid #ccc !important;
  background-size: 61% !important;
}

.nav-tabs button#functionAlerts {
  background: rgba(255, 255, 255, 0.15) url(/assets/images/icons/iconAlertWhite.svg) center center no-repeat !important;
  background-size: 37% !important;
  margin-right: 5px;
  margin-right: 15px;
}

.nav-tabs button#functionAlerts:hover {
  background: #ebeff1 url(/assets/images/icons/iconAlert.svg) center center no-repeat !important;
  background-size: 37% !important;
  border: 1px solid #fff;
}

.nav-tabs button#functionAlerts.selected {
  background: #ebeff1 url(/assets/images/icons/iconAlert.svg) center center no-repeat !important;
  color: #fff;
  background-size: 37% !important;
}

.nav-tabs button#functionAlerts.disabled {
  background: #fff url(/assets/images/icons/iconAlertWhite.svg) center center no-repeat;
  border-left: 1px solid #ccc !important;
  background-size: 37% !important;
}

.nav-tabs button#functionSales {
  background: rgba(255, 255, 255, 0.1) url(/assets/images/icons/iconSalesWhite.svg) center center no-repeat !important;
  background-size: 14% !important;
}

.nav-tabs button#functionSales:hover {
  background: #ebeff1 url(/assets/images/icons/iconSales.svg) center center no-repeat !important;
  background-size: 14% !important;
}

.nav-tabs button#functionSales.selected {
  background: #ebeff1 url(/assets/images/icons/iconSales.svg) center center no-repeat !important;
  background-size: 14% !important;
  color: #34677c !important;
}

.nav-tabs button#functionKeywords {
  background: rgba(255, 255, 255, 0.1) url(/assets/images/icons/ASO_white.svg) center center no-repeat !important;
  background-size: 80% !important;
  margin-right: 5px;
  margin-right: 15px;
}

.nav-tabs button#functionKeywords:hover {
  background: #ebeff1 url(/assets/images/icons/ASO.svg) center center no-repeat !important;
  background-size: 80% !important;
}

.nav-tabs button#functionKeywords.selected {
  background: #ebeff1 url(/assets/images/icons/ASO.svg) center center no-repeat !important;
  color: #34677c !important;
  background-size: 92% !important;
}

.nav-tabs button#functionKeywords.disabled {
  background: #ffffff url(/assets/images/icons/ASO_white.svg) center center no-repeat;
  background-size: 80% !important;
}

.nav-tabs button#functionCompetitor {
  background: rgba(255, 255, 255, 0.1) url(/assets/images/icons/iconCompetitorKeywordsWhite2.svg) center center no-repeat !important;
  background-size: 50% !important;
  position: relative;
  margin-right: 16px;
}

.nav-tabs button#functionCompetitor:hover {
  background: #ebeff1 url(/assets/images/icons/iconCompetitorKeywords2.svg) center center no-repeat !important;
  background-size: 50% !important;
}

.nav-tabs button#functionCompetitor.selected {
  background: #ebeff1 url(/assets/images/icons/iconCompetitorKeywords2.svg) center center no-repeat !important;
  color: #34677c !important;
  background-size: 50% !important;
}

.nav-tabs button#functionCompetitor.disabled {
  background: #fff url(/assets/images/icons/iconCompetitorKeywordsWhite2.svg) center center no-repeat;
  border-left: 1px solid #ccc !important;
  background-size: 50% !important;
}

.nav-tabs button#functionKeywordsSpy {
  background: rgba(255, 255, 255, 0.1) url(/assets/images/icons/keywordSpyWhite.svg) center center no-repeat !important;
  background-size: 50% !important;
  position: relative;
}

.nav-tabs button#functionKeywordsSpy:hover {
  background: #ebeff1 url(/assets/images/icons/keywordSpy.svg) center center no-repeat !important;
  background-size: 50% !important;
}

.nav-tabs button#functionKeywordsSpy.selected {
  background: #ebeff1 url(/assets/images/icons/keywordSpy.svg) center center no-repeat !important;
  color: #34677c !important;
  background-size: 50% !important;
}

.nav-tabs button#functionKeywordsSpy.disabled {
  background: #fff url(/assets/images/icons/keywordSpyWhite.svg) center center no-repeat;
  border-left: 1px solid #ccc !important;
  background-size: 50% !important;
}

.nav-tabs button#functionSettings {
  background: rgba(255, 255, 255, 0.1) url(/assets/images/icons/iconSettingsWhite.svg) center center no-repeat !important;
  background-size: 40% !important;
  margin-right: 15px;
}

.nav-tabs button#functionSettings:hover {
  background: #ebeff1 url(/assets/images/icons/iconSettings2.svg) center center no-repeat !important;
  background-size: 40% !important;
}

.nav-tabs button#functionSettings.selected {
  background: #ebeff1 url(/assets/images/icons/iconSettings2.svg) center center no-repeat !important;
  color: #34677c !important;
  background-size: 40% !important;
}

.nav-tabs button#functionSettings.disabled {
  background: #fff url(/assets/images/icons/iconSettingsWhite.svg) center center no-repeat;
  border-left: 1px solid #ccc !important;
  background-size: 40% !important;
}

.nav-tabs button#functionCustomTools {
  background: rgba(255, 255, 255, 0.1) url(/assets/images/icons/customToolsWhite.svg) center center no-repeat !important;
  background-size: 65% !important;
  margin-right: 15px;
}

.nav-tabs button#functionCustomTools:hover {
  background: #ebeff1 url(/assets/images/icons/customTools.svg) center center no-repeat !important;
  background-size: 65% !important;
}

.nav-tabs button#functionCustomTools.selected {
  background: #ebeff1 url(/assets/images/icons/customTools.svg) center center no-repeat !important;
  color: #34677c !important;
  background-size: 65% !important;
}

.nav-tabs button#functionCustomTools.disabled {
  background: #fff url(/assets/images/icons/customToolsWhite.svg) center center no-repeat;
  border-left: 1px solid #ccc !important;
  background-size: 65% !important;
}

.nav-tabs button#functionReportGenerator {
  background: rgba(255, 255, 255, 0.1) url(/assets/images/icons/reportGeneratorWhite.svg) center center no-repeat !important;
  background-size: 57% !important;
  margin-right: 0;
}

.nav-tabs button#functionReportGenerator:hover {
  background: #ebeff1 url(/assets/images/icons/reportGenerator.svg) center center no-repeat !important;
  background-size: 57% !important;
}

.nav-tabs button#functionReportGenerator.selected {
  background: #ebeff1 url(/assets/images/icons/reportGenerator.svg) center center no-repeat !important;
  color: #34677c !important;
  background-size: 57% !important;
}

.nav-tabs button#functionReportGenerator.disabled {
  background: #fff url(/assets/images/icons/reportGeneratorWhite.svg) center center no-repeat;
  border-left: 1px solid #ccc !important;
  background-size: 57% !important;
}

.nav-tabs button#functionReportList {
  background: rgba(255, 255, 255, 0.1) url(/assets/images/icons/reportGeneratorListWhite4.svg) center center no-repeat !important;
  background-size: 60% !important;
}

.nav-tabs button#functionReportList:hover {
  background: #ebeff1 url(/assets/images/icons/reportGeneratorList4.svg) center center no-repeat !important;
  background-size: 60% !important;
}

.nav-tabs button#functionReportList.selected {
  background: #ebeff1 url(/assets/images/icons/reportGeneratorList4.svg) center center no-repeat !important;
  color: #34677c !important;
  background-size: 60% !important;
}

.nav-tabs button#functionReportList.disabled {
  background: #fff url(/assets/images/icons/reportGeneratorListWhite4.svg) center center no-repeat;
  border-left: 1px solid #ccc !important;
  background-size: 60% !important;
}

.nav-tabs button#functionReviews {
  background: rgba(255, 255, 255, 0.15) url(/assets/images/icons/iconReviewsWhite.svg) center center no-repeat !important;
  background-size: 49% !important;
}

.nav-tabs button#functionReviews:hover {
  background: #ebeff1 url(/assets/images/icons/iconReviews.svg) center center no-repeat !important;
  background-size: 49% !important;
}

.nav-tabs button#functionReviews.selected {
  background: #ebeff1 url(/assets/images/icons/iconReviews.svg) center center no-repeat !important;
  color: #34677c !important;
  background-size: 49% !important;
}

.nav-tabs button#functionReviews.disabled {
  background: #fff url(/assets/images/icons/iconReviewsWhite.svg) center center no-repeat;
  background-size: 49% !important;
}

.nav-tabs button#functionCMALabs {
  background: rgba(255, 255, 255, 0.15) url(/assets/images/icons/CMA_Logo_LABS_white.svg) top center no-repeat !important;
  background-size: 75% !important;
  margin-right: 5px;
  margin-right: 15px;
}

.nav-tabs button#functionCMALabs:hover {
  background: #ebeff1 url(/assets/images/icons/CMA_Logo_LABS.svg) top center no-repeat !important;
  background-size: 78% !important;
}

.nav-tabs button#functionCMALabs.selected {
  background: #ebeff1 url(/assets/images/icons/CMA_Logo_LABS.svg) top center no-repeat !important;
  color: #34677c !important;
  background-size: 78% !important;
}

.nav-tabs button#functionCMALabs.disabled {
  background: #fff url(/assets/images/icons/CMA_Logo_LABS_white.svg) top center no-repeat;
  background-size: 78% !important;
}

.nav-tabs button#functionWorldCharts {
  background: rgba(255, 255, 255, 0.15) url(/assets/images/icons/worldcharts_white.svg) center center no-repeat !important;
  background-size: 47% !important;
}

.nav-tabs button#functionWorldCharts:hover {
  background: #ebeff1 url(/assets/images/icons/worldcharts.svg) center center no-repeat !important;
  background-size: 47% !important;
}

.nav-tabs button#functionWorldCharts.selected {
  background: #ebeff1 url(/assets/images/icons/worldcharts.svg) center center no-repeat !important;
  color: #34677c !important;
  background-size: 47% !important;
}

.nav-tabs button#functionWorldCharts.disabled {
  background: #fff url(/assets/images/icons/worldcharts_white.svg) center center no-repeat;
  background-size: 47% !important;
}

.nav-tabs button:link,
.nav-tabs button:hover {
  background-size: 18%;
  /* background-color: rgba(229, 87, 87, 0.2) !important; */
}

.nav-tabs span.new {
  width: auto;
  border-radius: 6px;
  background: red;
  display: inline-block;
  position: relative;
  top: -12px;
  padding: 6px 5px 0px 5px;
  left: 35px;
  font-size: 9px;
  position: absolute;
  z-index: 1000;
  color: #fff !important;
}

.nav-tabs span.new:hover {
  color: #fff !important;
  pointer-events: none;
}

.nav-tabs span.notified {
  width: auto;
  border-radius: 50px;
  background: red;
  display: inline-block;
  position: relative;
  top: -6px;
  padding: 6px 5px 0px 5px;
  left: 35px;
  font-size: 9px;
  position: absolute;
  z-index: 1000;
  color: #fff !important;
  min-width: 24px;
}

.nav-tabs span.notified:hover {
  color: #fff !important;
  pointer-events: none;
}

.nav-tabs.plattforms {
  margin-top: 31px;
  margin-bottom: 22px;
}

.nav-tabs.plattforms .selected a {
  color: #fff;
}

/* Firefox Fix */
@-moz-document url-prefix() {
  .nav-tabs.plattforms {
    margin: 40px 0 32px 0;
  }
}

@media (max-width: 645px) {
  .non-scrollable-tabs {
    background: linear-gradient(90deg, #34677c 0%, #3e4758 550%) !important;
  }

  .scrollable-tabs {
    overflow-x: scroll;
    margin-left: 150px;
  }
}

.functionrow {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.functionrow::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

@media (max-width: 611px) {
  .non-scrollable-tabs {
    background: linear-gradient(90deg, #34677c 0%, #3e4758 330%) !important;
  }
}

@media (max-width: 300px) {
  .logo-parent {
    display: none;
  }

  .non-scrollable-tabs {
    background: linear-gradient(90deg, #34677c 0%, #3e4758 150%) !important;
  }
}

.tag-dropdown {
  max-height: 80vh;

  &__item {
    &:hover {
      opacity: 0.8;
    }
  }
}

.app-select-item {
  display: flex !important;
  justify-content: center;
  align-items: center;

  .ant-card:hover {
    background-color: $link-hover;
  }
}

.app-select-card:hover {
  cursor: pointer;
}

.app-select-card .ant-card-body {
  padding: 5px;
  padding-left: 10px;
  width: 90%;
}

.app-add-button {
  margin-left: 5px;
  padding-left: 8px;
  padding-right: 8px;
  position: absolute;
  right: -7px;
  top: -7px;
  border: medium none !important;
  background-color: transparent !important;

  &:hover {
    background-color: transparent !important;
  }
}

.floatright {
  float: right;
  display: flex;
}

.main-login {
  margin-right: 15px;

  button {
    padding: 12px 13px 16px 13px !important;
    font-size: 1em;
    color: #fff;
    min-width: 50px;
    height: 45px;
    background-color: #ffffff26 !important;
    transition: background 0.2s ease;
    border-radius: 4px 4px 0 0 !important;
    border: none !important;
    cursor: pointer;
    vertical-align: middle;
    margin-left: 15px;

    span {
      font-size: 20px;
    }
  }
}

.main-login .selected {
  border: 1px solid rgba(255, 255, 255, 1);
  color: #34677c !important;
}

.main-login button:hover:disabled {
  background-color: #f0f3f7 !important;
  color: #fff !important;
}

.main-login button.disabled:hover {
  color: #fff !important;
}

.main-login button:hover:before {
  position: absolute;
  float: left;
  top: -20px;
  left: 0;
  font-size: 0.8em;
  background: transparent;
  color: #ebeff1;
  cursor: pointer;
  z-index: 10000000;
  width: auto;
  height: 15px;
  vertical-align: middle;
  text-align: left;
  width: 140px;
  opacity: 0.6;
}

.ant-card-meta-title {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ant-list-grid.ant-list-item-content {
  width: 100%;
}

.ant-popover {
  z-index: 990;
}

.workspacelabel {
  transform: rotate(-90deg);

  /* Legacy vendor prefixes that you probably don't need... */

  /* Safari */
  -webkit-transform: rotate(-90deg);

  /* Firefox */
  -moz-transform: rotate(-90deg);

  /* IE */
  -ms-transform: rotate(-90deg);

  /* Opera */
  -o-transform: rotate(-90deg);

  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}

.hamburger  {
  background-color: transparent !important;
}

.hamburger hover {
  background-color: transparent !important;
}
