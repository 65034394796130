//Global Styles
*,
body,
html {
	font-family: "Plus Jakarta Sans", Arial, Helvetica, sans-serif;

	#react_app_container,
	#placeHolder {
		background: linear-gradient(to bottom, #fff 0%, #fff 100%) !important;
	}

	#placeHolder {
		width: 90vw;

		margin: auto;
	}

	#react_app_container {
		overflow-x: auto;
		min-height: 95vh;
	}
}

.hidden {
	opacity: 0;
}

//Buttons
.add-button {
	background-color: $add-button;
	color: #fff;
	border: $add-button;
	border-radius: 3px;

	&--inverse {
		border: $add-button solid 1px;
		background-color: #fff;
		color: $add-button;
	}

	&:hover {
		background-color: $add-button;
		opacity: 0.8;
		color: #fff;
	}

	&:not(:disabled):hover {
		background-color: $add-button;
		opacity: 0.8;
		color: #000000;
	}

	&:focus {
		background-color: $add-button;
		color: #fff;
	}
}

.appinfo-add-button {
	background-color: transparent;
}

.signup-button {
	background-color: $table-header;
	color: $inverse-header;
	border: $table-header;
	border-radius: 3px;

	&:hover {
		background-color: $inverse-header;
		color: $table-header;
	}

	&:focus {
		background-color: $table-header;
		color: $inverse-header;
		border: $table-header;
	}

	&--inverse {
		background-color: $inverse-header;
		color: $table-header;

		&:hover {
			background-color: $table-header;
			color: $inverse-header;
		}
	}
}

.ant-input-group {
	.add-button {
		border-top-right-radius: 3px !important;
		border-bottom-right-radius: 3px !important;
	}
}

.add-link {
	color: $add-button !important;

	&--account {
		padding: 0;
	}

	&:hover {
		opacity: 0.8;
	}
}

.edit-button {
	color: $button-red;
	border-color: #fff;
	border-radius: 3px;

	&:hover {
		color: $button-red;
		opacity: 0.8;
		border-color: $button-red;
	}
}

.delete-button {
	border-radius: 3px;

	&:hover {
		color: #fff;
		background-color: $danger;
	}

	&--hover-visible {
		visibility: hidden;

		&:hover {
			visibility: visible;
		}
	}
}

.disabled-button {
	opacity: 0.3;
}

//Tooltip
.tooltip {
	&__title {
		color: #fff;
		margin-bottom: 0;
	}

	&__body {
		color: #fff;
	}
}

//containers
.box-shadow {
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	border-radius: 5px;
	background-color: #fff;
	padding: 10px;
	margin-bottom: 7px;
}

.box-shadow-large {
	box-shadow: 0 0 20px 6px rgba(178, 178, 178, 0.16);
	border-radius: 5px;
	background-color: #fff;
	padding: 10px;
	margin-bottom: 7px;
}

.box-shadow h3 {
	text-align: left;
	margin-top: 15px;
	font-size: 0.9em;
	color: rgba(52, 103, 124, 0.7);
}

//headers
.header {
	display: flex;
	align-items: center;
	letter-spacing: 0.5px;
	font-weight: 700;
	margin: 0;
	color: $table-header;

	&--page {
		line-height: 56px;
		font-size: 36px;
		justify-content: center;
	}

	&--main {
		line-height: 60px;
		font-size: 38px;
		color: $applyzersubcolor;
	}

	&--section {
		font-size: 28px;
		line-height: 40px;
		margin-left: 5px;
	}
}

//Content
.content-body {
	width: 98vw;
	margin: 0px auto 0;
}

.explanation {
	margin-top: 5px;
	display: block;
	background: #fff;
	border-radius: 5px;
	padding: 5px;
	margin-bottom: 5px;
	font-size: 0.85em;
	line-height: 1.4em;
}

.description {
	font-size: 0.9em;
	color: #34677c;
	margin-bottom: 20px;
	line-height: 1.5em;
	font-family: "Arial";
	text-align: center;
}

.stepball {
	background-color: #e55757;
	border-radius: 100px;
	color: #fff;
	width: 50px;
	height: 30px;
	font-weight: normal;
	font-size: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto 5px auto;
	padding-top: 2px;
}

// Override AntD styles

.ant-modal-close-x {
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
}

.ant-modal-mask {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1000;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.45);
	filter: blur(8px);
	-webkit-filter: blur(8px);
	backdrop-filter: blur(8px);
}

th.ant-table-cell,
th.ant-table-cell-fix-left {
	background-color: $table-header !important;
	color: #fff !important;

	.active svg {
		color: $link-hover;
	}

	svg {
		color: #fff;
	}

	svg:hover,
	svg:active {
		color: $link-hover;
	}
}

.ant-table-filter-column {
	padding-right: 1% !important;
}

.ant-table-cell-fix-left {
	background-color: inherit !important;
}

.ant-table-tbody .ant-table-row:nth-child(even) td {
	background-color: $off-white !important;
}

.ant-table-tbody .ant-table-row:hover {
	opacity: 0.8 !important;
}

.ant-table-tbody .ant-table-row-selected td,
.ant-table-tbody .ant-table-row-selected:nth-child(even) td {
	background-color: $link-hover !important;
}

.ant-select-item-option-content,
.ant-select-selection-item {
	display: flex;
	align-items: center;
}

.ps {
	&__rail-y {
		left: 0px !important;
	}

	&__thumb-y {
		right: 10px !important;
	}
}

.filter-bar__block {
	padding-right: 40px;
}

.filter-bar__block p {
	font-size: 10pt;
	margin-bottom: 5px;
}

.ant-modal,
.ant-modal-content {
	padding-top: 0px;
}

.ant-tag-checkable {
	border-color: #34677c;
}

.ant-tag-checkable-checked2 {
	background-color: blue;
	color: #fff;
}

.dx-command-select {
	width: 20px;
	min-width: 20px;
}

.nav-header {
	width: 100%;
	margin: auto;
	display: inline-flex;
	gap: 24px;
	height: 80px;
	background: linear-gradient(90deg, #34677c 0%, #3e4758 100%) !important;
}

.ant-segmented-item {
	background-color: #fff !important;
	color: $applyzersubcolor !important;
}

.ant-segmented-item-selected {
	color: #fff !important;
	background-color: $applyzersubcolor !important;
}

.nav-header button {
	position: relative;
	margin-top: 10px;
	min-width: 55px;
	background-color: #ffffff26;
	border: none !important;
	height: 55px;
	color: white;
}

.ant-tag {
	border-radius: 5px;
}

.ant-card-body {
	padding: 10px;
	padding-bottom: 0px;
}

.app-add-button {
	margin-left: 5px;
	padding-left: 8px;
	padding-right: 8px;
	position: absolute;
	right: 0px;
	top: 0px;
	border: medium none;
	background-color: transparent;

	&:hover {
		background-color: transparent;
	}
}

.ant-select-dropdown {
	z-index: 10000;
}

.ant-notification {
	z-index: 10000;
}

.ant-modal-content {
	top: 80;
}

.ant-modal-mask {
	z-index: 3000;
}

.ant-modal-wrap {
	z-index: 3000;
}

.ant-btn {
	background-color: #fff !important;
	border-color: $applyzersubcolor !important;
	color: $applyzersubcolor !important;
	/* If you also want to change the border color */
}

.ant-btn-primary {
	background-color: $applyzersubcolor !important;
	border-color: $applyzersubcolor !important;
	color: #fff !important;
	/* If you also want to change the border color */
}

.ant-tabs-tab {
	background-color: #ffffff !important;
	color: $applyzersubcolor !important;
}

.dx-button {
	background-color: #fff !important;
	border-color: $applyzersubcolor !important;
	color: $applyzersubcolor !important;
}

.dx-icon {
	color: $applyzersubcolor !important;
}

.full-screen-spinner {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 9999;
	display: flex;
	align-items: center;
	justify-content: center;
}

.bold-text {
	font-weight: 700;
}

.ant-collapse-item {
	background-color: #ffffff(0, 0, 0, 0.88);
}

.dx-datagrid-headers {
	color: #000 !important;
}

.wave-image {
	z-index: -1;
	position: absolute;
	top: auto;
	bottom: 13%;
	left: 0%;
	right: 0%;
}

.wave-image.home-2-hero-wave {
	left: -3%;
	transform: translate3d(100%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
		rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
	transform-style: preserve-3d;
}

.wave-cover-left {
	z-index: 201;
	background-color: rgba(0, 0, 0, 0);
	position: absolute;
	top: -1%;
	bottom: 0%;
	left: 0%;
	right: 0%;
}

.image.contain {
	object-fit: contain;
	overflow: visible;
}

.image.contain {
	z-index: 200;
	object-fit: contain;
}

.image {
	z-index: 100;
	width: auto;
	height: auto;
	object-fit: fill;
	background-color: rgba(0, 0, 0, 0);
	position: relative;
}
