.filters {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin-bottom: 1%;
  &__options {
    margin-bottom: 0;
  }
  &__option {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 10px;
  }
  .toobox-btn {
    margin-left: 10px;
  }
}

.searchResults {
  background: rgba(255, 255, 255, 1);
  position: absolute;
  left: 0;
  top: 40px;
  z-index: 10000;
  width: 167%;
  height: auto;
  max-height: 85vh;
  overflow: auto;
  transition: all 0.1s ease-out;
  box-shadow: 0 0 25px #888;
  border-radius: 5px;
}

.searchResultsModal {
  background: rgba(255, 255, 255, 1);
  position: relative;
  left: 0;
  top: 10px;
  z-index: 10000;
  width: 100%;
  height: auto;
  max-height: 70vh;
  overflow: auto;
  //transition: all .1s ease-out;
  //box-shadow: 0 0 25px #888;
  border-radius: 5px;
}

.search-bar {
  border-radius: 5px;
}

.select-label {
  margin-bottom: 8px;
  font-size: 10pt;
}

.apps-form-container {
  flex-direction: column;
  margin-top: 5px;
}

.apps-form {
  width: 100%;
  margin-top: 10px;
  &__apps {
    width: 100%;
    padding-bottom: 5px;
    margin-left: 25px;
  }
  &__app-display {
    display: inline-block;
    margin-bottom: 5px;
    margin-left: -25px;
    .ant-avatar > img {
      border-radius: 5px;
    }
  }
}



.market-select {
  &__option {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    width: 30px;
    height: 30px;
    &--image {
      height: 20px !important;
      width: 20px !important;
    }
    &--text {
      font-size: 10px;
    }
  }
}

.country-select {
  width: 240px;
  &__option {
    height: 25px;
    width: 100%;
    padding: 0 0 0 5px !important;
  }
  &__flag {
    height: 20px;
    width: 35px;
  }
  &__text {
    margin-left: 25px;
  }
}

.ant-select-open.country-select img {
  display: none;
}

.app-select {
  width: 240px;
  &--header {
    .ant-select-selector {
      min-width: 255px;
      padding: 6px 10px 6px 30px;
      border-radius: 200px !important;
    }
  }
  &__my-apps-search-btn {
    top: -36px !important;
    border: none;
  }
  &__text {
    font-size: 1em;
    width: 75%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    color: #444;
    text-indent: 0px;
    line-height: 1em;
  }
  &__options {
    border-radius: 5px;
  }
  &__option {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  &__option-body {
    display: flex;
    justify-content: space-between;
    width: 75%;
    padding: 11px 12px;
  }
  &__icon {
    width: 40px;
    height: 40px;
    margin-right: 20px;
    border-radius: 10px;
    vertical-align: top;
    float: left;
    box-shadow: 0 0 15px #bbb;
    z-index: 0;
    position: relative;
    &--no-logo {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &__market-icon {
    width: 20px;
    height: 20px;
    display: inline-block;
    z-index: 1000;
    position: absolute;
    left: 72px;
    border-radius: 4px;
  }
}

.tag-dropdown {
  &__item {
    &:hover {
      cursor: pointer;
    }
  }
}

.edit-tag-controls {
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &__header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .header {
      justify-content: center;
      margin-right: 10px;
    }
  }
}

@media (max-width: 1100px) {
  .filters {
    &--keyword-research {
      flex-direction: column;
      align-items: flex-start;
      .filters__options {
        justify-content: space-between;
        width: 100%;
      }
      .toolbox-btn {
        width: 95%;
        margin: 20px;
      }
    }
  }
}

@media (max-width: 969px) {
  .app-select {
    &__my-apps-search-btn {
      top: 6px !important;
      right: 6px !important;
    }
  }
}

@media (max-width: 800px) {
  .keyword-research-filters-container {
    min-width: 350px;
  }
  .filters--keyword-research {
    .filters {
      &__options {
        flex-direction: column;
        align-items: flex-start;
      }

      &__option {
        margin-left: 0;
        &:last-of-type {
          margin-top: 15px;
        }
      }
    }
    > .filters__option {
      margin-left: 20px;
    }

    .toolbox-btn {
      margin-left: 0;
      width: 240px;
    }
  }
}

@media (max-width: 510px) {
  .filters {
    &--tag-manager {
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
    }
    &__option {
      margin-bottom: 20px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  .edit-tag-controls {
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    margin-bottom: 20px;
  }
}
