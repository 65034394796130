.get-started {
  width: 99vw;
  margin: auto;
  display: block;

  .header {
    justify-content: center;
    text-align: center;
  }

  &__head {
    margin-top: 25px;
  }

  &__section {
    margin: 1% 5%;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    padding: 1%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &--add-tag {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;

      // height: 150px;
      input.ant-input {
        width: 60vw;
      }
    }
  }

  &__image {
    margin-bottom: 10px;
    border: 1px solid #aaa;
    max-width: 70vw;
  }
}

@media (max-width: 650px) {
  .get-started {
    &__section {
      margin: 15px;
      padding: 15px;

      &--add-tag {
        margin-bottom: 25px;
      }

      .ant-space-align-center {
        justify-content: center;

        input.ant-input {
          width: 80vw;
          margin-bottom: 10px;
        }
      }
    }
  }
}

.card-block {
  border: 2px solid #00b3ff;
  width: 45%;
  max-width: 450px;
  margin: 2.5%;
  padding: 20px 25px 15px 25px;
  border-radius: 0px;
  margin-bottom: 25px;
  margin-top: 2px;
  width: 75%;
  box-sizing: border-box;
  color: #000000;
  background-color: #ffffff;

  font-size: 15px;
  letter-spacing: 1px;
}

.card-block-green {
  border: 1px solid #cfd8dc;
  width: 45%;
  margin: 2.5%;
  padding: 20px 25px 15px 25px;
  border-radius: 0px;
  margin-bottom: 25px;
  margin-top: 2px;
  width: 75%;
  box-sizing: border-box;
  color: #2c3e50;
  background-color: #40d44d;

  font-size: 15px;
  letter-spacing: 1px;
}

.custom-steps .ant-steps-item-container::before {
  display: none;
}

.ant-steps-item-container {
  display: none;
}
