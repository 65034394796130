.accounts-page {
  height: 85vh;
  margin-top: 3%;
  //background: url(/assets/images/elements/registerBackground.png) 50% 5%    no-repeat;
  background-image: url(/assets/images/wave.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  &__heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.signup-confirmation-page {
  background-image: url(/assets/images/wave.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 100vh;
  margin-top: 0;
  display: flex;
  justify-content: center; /* centers horizontally */
  align-items: center;

  &__verifying {
    margin-bottom: 20px;
    padding: 30px 50px;
    text-align: center;
    background-color: none;
    border-radius: 4px;

    img {
      height: 180px;
    }
  }
}

.profile-section {
  .account-form {
    width: 650px;
  }
}

.account-form {
  background-color: #fff;
  border-radius: 3px;

  margin: auto;
  padding-left: 50px;
  padding-right: 50px;

  .login-form-button {
    margin-right: 15px;
  }

  h3 {
    text-align: center;
    letter-spacing: 0.5px;
    color: #34677c;
    margin: 0 auto 20px;
    font-weight: 700;
    font-size: 20px;
  }

  .subscription-details {
    padding: 15px;
    margin-bottom: 20px;
    border-bottom: 0.5px solid #777;

    h4 {
      letter-spacing: 0.5px;
      color: #34677c;
      font-weight: 600;
      margin-bottom: 20px;
      font-size: 16px;
    }

    p {
      margin-left: 5px;
      font-weight: 200;
      color: #222;
    }

    &__buttons {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
    }

    &:last-of-type {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: none;
    }
  }
}

.login-form {
  height: auto;
}

.registration-form {
  height: auto;
  width: 600px;
}

.pricing-faq {
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
  display: flex;
  padding: 50px;
}
.pricing-faq .header {
  padding-bottom: 20px;
}

.pricing-page {
  background-image: url(/assets/images/wave.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 100%;
  padding: 50px;

  &__billing {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
  }

  &__billing-options {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 60px;
  }
  &__billing-subline {
    margin-bottom: 10px;
  }
}

.pricing-table {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__categories {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 30%;
  }

  &__button {
    margin-bottom: 25px;
    padding: 20px 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;

    &--pro {
      background-color: #81e608;
      color: #000;
      border: $pro-color;

      &--inverse {
        border: $pro-color solid 1px;
        background-color: #fff;
        color: $pro-color;
      }

      &:hover {
        background-color: $pro-color;
        color: #fff;
      }

      &:focus {
        background-color: $pro-color;
        color: #fff;
      }
    }
  }

  &__category {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-radius: 5px;
    background-color: #fff;
    padding: 15px;
    width: 99%;
    min-width: 250px;
    max-width: 455px;
  }

  &__pricing-box {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 80px;
    height: 80px;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 15px;
    font-weight: 600;
    right: -90%;
    box-shadow: rgb(0 0 0 / 25%) 0px 25px 50px -12px;
    border-radius: 5px;

    &--free {
      background-color: $table-header;
      font-size: 22px;
      top: -21%;
    }

    &--pro {
      background-color: $pro-color;
      top: -23%;
    }

    &--data {
      background-color: $add-button;
      top: -30%;
    }
  }

  &__ribbon {
    position: relative;
    left: -21px;
    top: -24px;
    z-index: 1;
    overflow: hidden;
    width: 95px;
    height: 95px;
    text-align: right;

    span {
      font-size: 13px;
      font-weight: bold;
      color: #fff;
      text-transform: uppercase;
      text-align: center;
      line-height: 25px;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      width: 100px;
      display: block;
      box-shadow: 0 3px 10px -5px rgb(0 0 0);
      position: absolute;
      top: 19px;
      left: -21px;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 100%;
        z-index: -1;
        border-left: 3px solid rgba(0, 0, 0, 0.3);
        border-right: 3px solid transparent;
        border-bottom: 3px solid transparent;
        border-top: 3px solid rgba(0, 0, 0, 0.3);
      }

      &::after {
        content: "";
        position: absolute;
        right: 0;
        top: 100%;
        z-index: -1;
        border-left: 3px solid transparent;
        border-right: 3px solid rgba(0, 0, 0, 0.3);
        border-bottom: 3px solid transparent;
        border-top: 3px solid rgba(0, 0, 0, 0.3);
      }
    }

    &--free {
      span {
        background: $table-header;
      }
    }

    &--pro {
      span {
        background: $pro-color;
      }
    }

    &--data {
      span {
        background: $add-button;
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px 0 13px 43px;
    color: $table-header;

    &--head {
      font-size: 16px;
      display: flex;
      align-items: flex-end;
    }

    &--info {
      font-size: 14px;
      padding-left: 29px;
      margin-top: 8px;
    }

    &--free {
      padding-bottom: 29px;
    }

    &--data {
      padding-bottom: 40px;
    }
  }

  &__slider {
    margin: 0;
    padding: 20px;
  }

  &__slider-text {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
  }

  &__slider-counter {
    background: $button-red;
    border-radius: 4px;
    color: #fff;
    padding: 5px 5px 5px 5px;
    margin-right: 5px;
  }
}

.tick {
  margin-right: 10px;
  font-size: 18px;

  &--free {
    color: $table-header;
  }

  &--pro {
    color: $applyzersubcolor;
  }

  &--data {
    color: $add-button;
  }
}

@media (max-width: 1300px) {
  .pricing-page {
    padding: 20px;
  }

  .pricing-table {
    &__item {
      padding-left: 15px;
    }
  }
}

@media (max-width: 970px) {
  .pricing-table {
    &__pricing-box {
      right: -80%;

      &--free {
        top: -17%;
      }

      &--pro {
        top: -18%;
      }

      &--data {
        top: -24%;
      }
    }
  }
}

@media (max-width: 780px) {
  .pricing-table {
    &__categories {
      flex-direction: column;
      align-items: center;
    }

    &__container {
      width: 90%;
      margin-top: 20px;
    }

    &__pricing-box {
      right: -90%;
      top: -130px;
      // &--free {
      //     top: -17%;
      // }
      // &--pro {
      //     top: -18%;
      // }
      // &--data {
      //     top: -24%;
      // }
    }
  }
}

@media (max-width: 575px) {
  .registration-form {
    height: 500px;
  }
}

@media (max-width: 769px) {
  .signup-confirmation-page {
    padding: 35% 0;
  }
}

.boxshadowPadMid {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.boxshadowPadMid::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.pricing-description {
  display: flex;
  justify-content: center;
}

.videoContainer {
  margin-bottom: 2rem;
  background-color: #f9f9f9;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  min-width: 80vw; /* Use a percentage or vw for responsive width */
  min-height: 50vh;
  margin-left: auto; /* Center the video container */
  margin-right: auto;
}

.videoTitle {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.videoDescription {
  font-size: 1rem;
  color: #666;
}

iframe {
  width: 100%; /* Make iframe take the full width of its container */
  height: 50vh; /* Adjust height automatically */
  border-radius: 5px;
  margin-top: 1rem;
}

.tutorialContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
}

.pageTitle {
  font-size: 2rem;
  color: #333;
  margin-bottom: 2rem;
}
