.pricing-card {
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 20px 6px #b2b2b229;
  border: none;
  border-radius: 5px;
  background-color: #fefefe;

  min-width: 400px;

  &__banner {
    background-color: #ffffff;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: left;
    color: #000000;
    padding-left: 20px;
  }

  &__header {
    color: #f27405;
    padding-left: 20px;
    font-weight: bold;
    font-size: 38px;
  }

  &__header span {
    color: #727272;
    font-size: 12pt;
    font-style: normal;
  }

  &__headersub {
    color: #34677c;
    font-size: 10pt;
    padding-left: 20px;
  }

  &__headersubvat span {
    color: #727272;
    font-size: 9pt;
  }

  &__item {
    margin: 8px;
    font-size: 14px;
    align-items: left;
    padding: 20px;
  }

  &__button {
    margin: 10px;
    border-radius: 50px;
    &:hover {
      color: #fff !important;
      background-color: #f27405 !important;
    }
  }

  .bold {
    font-weight: 700;
  }
}

.custom-list {
  list-style-type: none; /* Removes default bullets */
  padding-left: 0;
}

.custom-list li {
  padding-left: 1.9em;
  padding-bottom: 0.9em;
  position: relative;
}

.custom-list li .anticon {
  position: absolute;
  left: 0;
  /* Adjust for vertical alignment */
}

.pricing-columns {
  display: flex;

  &__tabs,
  &__empty-column {
    width: 33%;
  }

  &__tabs {
    transform: translate(0px, 0px);
    position: relative;
    display: flex;
    transition: opacity 0.3s;
    flex: 1 0 auto;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 51px;
  }

  &__content {
    width: 33%;
  }

  &__tab {
    padding: 8px 24px;
    text-align: center;
    position: relative;
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    background: transparent;
    border: 0;
    outline: none;
    cursor: pointer;
    color: $applyzersubcolor;
    background-color: #fff;
    margin-bottom: 16px;
    width: 195px;
    height: 40px;

    &--active {
      border-right: $applyzersubcolor solid 2px;
      background-color: $applyzersubcolor;
      color: #ffffff;
    }
  }
  &__tab-button {
    text-shadow: 0 0 0.25px currentcolor;
    outline: none;

    transition: all 0.3s;

    &--active {
      color: #ffffff;
      background-color: $applyzersubcolor;
    }
  }
}

.pricing-faq {
  width: 85vw;
  margin: auto;
}
